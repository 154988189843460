<template>
  <div class="home-casino">
    <SectionTitle title="Live casino" link="/livecasino" />
    <div class="home-casino__container">
      <div class="home-casino__container__card evo-card">
        <div class="home-casino__container__card--slide">
          <div class="carousel-container">
            <nuxt-link
              v-for="(image, index) in images"
              :key="image.id"
              :class="['carousel-item', `item-${getClassIndex(index)}`, { 'slide-out': isSlidingOut(image.id) }]"
              :to="image.link"
            >
              <CommonBaseImg :src="image.src" :alt="image.alt" />
            </nuxt-link>
          </div>
        </div>
        <div class="home-casino__container__card--content">
          <div class="title">
            <div class="signature">Sòng bài Việt đỉnh cao</div>
            <div class="position">#1</div>
          </div>
          <div class="logo">
            <CommonBaseImg src="/assets/images/home/casino/evo_title.webp" alt="slide-evo" />
          </div>
          <div class="list-link">
            <nuxt-link
              v-for="(item, index) in HOME_LIVE_CASINO_EVO"
              :id="`${item.title}`"
              :key="index"
              class="list-link__item"
              :to="item.link"
            >
              {{ item?.title }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="home-casino__container__card mg-card">
        <div class="home-casino__container__card--slide">
          <div class="carousel-container">
            <nuxt-link
              v-for="(image, index) in images"
              :key="image.id"
              :class="['carousel-item', `item-${getClassIndex(index)}`, { 'slide-out': isSlidingOut(image.id) }]"
              :to="image.link"
            >
              <CommonBaseImg :src="image.src" :alt="image.alt" />
            </nuxt-link>
          </div>
        </div>
        <div class="home-casino__container__card--content">
          <div class="title">
            <div class="signature">Casino thuần Việt</div>
            <div class="position">#2</div>
          </div>
          <div class="logo">
            <CommonBaseImg src="/assets/images/home/casino/mg_title.webp" alt="slide-evo" />
          </div>
          <div class="list-link">
            <nuxt-link
              v-for="(item, index) in HOME_LIVE_CASINO_MG"
              :id="`${item.title}`"
              :key="index"
              class="list-link__item"
              :to="item.link"
            >
              {{ item?.title }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { HOME_LIVE_CASINO_THUMBS, HOME_LIVE_CASINO_EVO, HOME_LIVE_CASINO_MG } from '~/resources/home'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
interface Image {
  src: string
  alt: string
  id: string
}
const images = ref<Image[]>(HOME_LIVE_CASINO_THUMBS)
const currentIndex = ref(0)
const firstImage = ref<Image | null>(null)
const rotateImages = () => {
  firstImage.value = images.value[currentIndex.value]
  setTimeout(() => {
    currentIndex.value = (currentIndex.value + 1) % images.value.length
  }, 500)
}
const getClassIndex = (index: number) => {
  return (index - currentIndex.value + images.value.length) % images.value.length
}
const isSlidingOut = (imageId: string) => {
  return firstImage.value?.id === imageId
}
onMounted(() => {
  const interval = setInterval(() => {
    rotateImages()
  }, 5000)
  onUnmounted(() => clearInterval(interval))
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/casino/index.scss"></style>
